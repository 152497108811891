
import { mixins, Options } from 'vue-class-component';
import {
  CodeTags,
  FlaskOutline,
  Music,
  Gamepad,
  Web,
  Folder,
  PlusCircle,
  TagText,
} from 'mdue';
import ProjectToggleFilter from '@/components/ProjectToggleFilter.vue';
import ProjectTagFilter from '@/components/ProjectTagFilter.vue';
import ProjectModal from '@/components/ProjectModal.vue';
import ProjectTeaser from '@/components/ProjectTeaser.vue';
import firebase from '../firebaseConfig';
import RandomizeMixin from '@/mixins/RandomizeMixin';

type Project = {
  categories: string[],
  class: string,
  created: string,
  description: string,
  filters: string[],
  status: string,
  tags: string[],
  title: string,
  type: string,
  url: string,
};

@Options({
  components: {
    ProjectToggleFilter,
    ProjectTagFilter,
    ProjectModal,
    ProjectTeaser,
    CodeTags,
    FlaskOutline,
    Music,
    Gamepad,
    Web,
    Folder,
    PlusCircle,
    TagText,
  },
})
export default class ProjectListing extends mixins(RandomizeMixin) {
  showModal = false;

  random: {[key: string]: {value: unknown, options: unknown[]}} = {
    Music: {
      value: 'Music',
      options: [
        'Muzak',
        'Earache',
        'Noise',
        'Ear food',
        'Aural pleasure',
        'Earbait',
      ],
    },
    MusicTitle: {
      value: 'Things that might tickle your eardrums, or smash them to bits...',
      options: [
        'Things that might tickle your eardrums, or smash them to bits...',
        'Non-discerning minds might call this music...',
        'Music for darklings...',
        'Subject yourself to my music...',
      ],
    },
    Gaming: {
      value: 'Gaming',
      options: [
        '"Games"',
        'Brainrot',
        'Is this fun?',
        'Bloody pixels',
        'Game over, man',
        'GPU candy',
        'Playbait',
        'Sweetcakes',
      ],
    },
    GamingTitle: {
      value: 'Things for folks who\'d rather be fragging...',
      options: [
        'Things for folks who\'d rather be fragging...',
        'Here there be dragons...',
        'These things will make you better at that game you suck at...',
        'You can\'t even play most of this crap...',
      ],
    },
    Websites: {
      value: 'Websites',
      options: [
        'Clickbait',
        'Webshites',
        'Browser candy',
        'Favorites',
        'Bookmarks',
        'Clicksores',
        'Eyesores',
      ],
    },
    WebsitesTitle: {
      value: 'Shady sites I\'ve created to convince you of something or another...',
      options: [
        'Shady sites I\'ve created to convince you of something or another...',
        'Probably just forms asking for money...',
        'Websites just like this one but different...',
        'I made these websites with my brain...',
      ],
    },
    Unreleased: {
      value: 'Unreleased',
      options: [
        'Workshop',
        'Neverware',
        'You wish...',
        'Half-baked',
        'Future failures',
        'Pre-vaporware',
        'Can\'t touch this',
        'Ghostbait',
        'Eye on the prize',
        'Procrasturbation',
      ],
    },
    UnreleasedTitle: {
      value: 'Things I haven\'t finished yet. Maybe I never will...',
      options: [
        'Things I haven\'t finished yet. Maybe I never will...',
        'Don\'t get your hopes up...',
        'Stuff for future generations...',
        'Maybe later, I\'m procrastinating...',
      ],
    },
    Code: {
      value: 'Code',
      options: [
        'Code den',
        'Boring',
        'tl;dr',
        'RTFM',
        'Gibberish',
        'Stack Underwhelm',
        'Nerdbait',
        'Dev pit',
      ],
    },
    CodeTitle: {
      value: 'Unbearable things like code and development libraries...',
      options: [
        'Unbearable things like code and development libraries...',
        'For developers only! All others, stay away...',
      ],
    },
    noResultsTitle: {
      value: 'Nein results!',
      options: [
        'Nein results!',
        'There\'s nothing here.',
        'It\'s lonely in here.',
        'Bam, brick wall.',
        'Game over. Continue?',
      ],
    },
    noResults: {
      value: 'Select some categories, quick!',
      options: [
        'Select some categories, quick!',
        'You went and fucked up, now...',
        'Ok, you unselected everything. Is this what you wanted?',
        'Why are you doing this to me?',
        'Don\'t hide my greatness!',
        'I order you to choose better categories immediately!',
        'Make better life choices.',
        'Look what you\'ve done... to my sheets...',
        'This is bullshit...',
      ],
    },
  };

  categories: {name: string, icon: string, startActive: boolean}[] = [
    {
      name: 'Music',
      icon: 'Music',
      startActive: true,
    },
    {
      name: 'Gaming',
      icon: 'Gamepad',
      startActive: true,
    },
    {
      name: 'Websites',
      icon: 'Web',
      startActive: true,
    },
    {
      name: 'Code',
      icon: 'CodeTags',
      startActive: false,
    },
  ];

  filters: {name: string, icon: string, startActive: boolean}[] = [
    {
      name: 'Unreleased',
      icon: 'FlaskOutline',
      startActive: false,
    },
  ];

  db: firebase.firestore.Firestore|null = null;

  projects: Project[] = [];

  tags: { [key: string]: number } = {};

  selectedTags: { [key: string]: boolean } = {};

  selectedProject: Project|false = false;

  includeCategories: {[key: string]: boolean} = {
    Music: true,
    Gaming: true,
    Websites: true,
    Code: false,
  };

  includeFilters: {[key: string]: boolean} = {
    Unreleased: false,
  };

  get filteredProjects(): Project[] {
    const filtered: Project[] = [];
    const hasSelectedTags = this.hasSelectedTags();

    this.projects.forEach((project) => {
      let included = false;

      Object.keys(this.includeCategories).every((category) => {
        if (this.includeCategories[category] && project.categories.includes(category)) {
          included = true;
          return false;
        }

        return true;
      });

      if (included) {
        project.filters.every((filter) => {
          if (!Object.prototype.hasOwnProperty.call(this.includeFilters, filter)
            || !this.includeFilters[filter]) {
            included = false;
            return false;
          }

          return true;
        });
      }

      if (included && hasSelectedTags) {
        Object.keys(this.selectedTags).every((tag) => {
          if (this.selectedTags[tag] && !project.tags.includes(tag)) {
            included = false;
            return false;
          }

          return true;
        });
      }

      if (included) {
        filtered.push(project);
      }
    });

    return filtered;
  }

  get availableTags(): { [key: string]: number } {
    const tags: { [key: string]: number } = {};

    this.filteredProjects.forEach((project) => {
      Object.values(project.tags).forEach((tag: string) => {
        if (!Object.prototype.hasOwnProperty.call(tags, tag)) {
          tags[tag] = 0;
        }

        tags[tag] += 1;
      });
    });

    const sortedTags = Object.keys(tags)
      .sort((first, second) => {
        if (this.selectedTags[first] === this.selectedTags[second]) {
          if (tags[first] === tags[second]) {
            if (first === second) {
              return 0;
            }

            return first.toUpperCase() < second.toUpperCase() ? -1 : 1;
          }

          return tags[first] > tags[second] ? -1 : 1;
        }

        return this.selectedTags[first] ? -1 : 1;
      })
      .reduce((obj: { [key: string]: number }, key) => {
        // eslint-disable-next-line
        obj[key] = tags[key];
        return obj;
      }, {});

    return sortedTags;
  }

  closeModal(): void {
    this.showModal = false;

    setTimeout(() => {
      this.selectedProject = false;
    }, 500);
  }

  toggleTag(tag: string, active: boolean): void {
    this.selectedTags[tag] = active;
  }

  toggleCategory(category: string, active: boolean): void {
    this.includeCategories[category] = active;
  }

  toggleFilter(filter: string, active: boolean): void {
    this.includeFilters[filter] = active;
  }

  hasSelectedTags(): boolean {
    let hasSelectedTags = false;

    Object.keys(this.selectedTags).every((tag: string) => {
      if (this.selectedTags[tag]) {
        hasSelectedTags = true;
        return false;
      }

      return true;
    });

    return hasSelectedTags;
  }

  // eslint-disable-next-line
  created() {
    this.$emitter.on('randomize', () => this.randomizeProjects());
    this.db = firebase.firestore();

    const converter = () => ({
      toFirestore: (data: Partial<Project>) => data,
      fromFirestore: (snap: firebase.firestore.QueryDocumentSnapshot) => snap.data() as Project,
    });

    this.db.collection('projects').withConverter(converter()).get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        const docData = doc.data();
        this.projects.push(docData);

        Object.values(docData.tags).forEach((tag: string) => {
          if (!Object.prototype.hasOwnProperty.call(this.tags, tag)) {
            this.tags[tag] = 0;
          }

          this.tags[tag] += 1;
          this.selectedTags[tag] = false;
        });
      });
    });
  }

  randomizeProjects(): void {
    function shuffleProjects(a: Project[]): Project[] {
      /* eslint-disable no-param-reassign */
      for (let i = a.length - 1; i > 0; i -= 1) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
      }
      return a;
      /* eslint-enable */
    }

    shuffleProjects(this.projects);
  }
}
