<template>
  <div class="app-inner">
    <header>
      <NavBar id="nav"></NavBar>
      <SocialLinks></SocialLinks>
    </header>

    <div class="site-content">
      <router-view/>
    </div>

    <footer>
      &copy; 2021 Ben McClure. <span class="dim-text">[Random common sense bullshit here.]</span>
    </footer>
  </div>
</template>

<style lang="stylus">
@import url('https://fonts.googleapis.com/css2?family=Aldrich&family=Oxygen&display=swap')

html,body
  margin 0

#app
  &::before
    background color-darker url('~@/assets/background.jpg') no-repeat center center
    background-size cover
    content ' '
    height 100%
    left 0
    position fixed
    top 0
    width 100%
    will-change transform
    z-index -1

.app-inner
  color color-light
  font-family font-family-main
  font-size font-size-normal
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
  text-align center

  .site-content
    margin-bottom spacing-large-fixed
    padding 0 spacing-normal-fixed spacing-normal-fixed
    text-align left

    p
      max-width: text-width

  h1, h2, h3, h4, h5, h6
    color color-lighter
    font-family font-family-alt
    max-width: text-width

  a
    color color-accent

    &:hover
      color color-highlight

  footer
    background-color alpha(color-darker, 0.75)
    bottom 0
    font-size font-size-small
    left 0
    padding spacing-small-fixed spacing-normal-fixed
    position fixed
    right 0

  .dim-text
    color color-dim
</style>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import NavBar from '@/components/NavBar.vue';
import SocialLinks from '@/components/SocialLinks.vue';

@Options({
  components: {
    NavBar,
    SocialLinks,
  },
})

export default class App extends Vue {}
</script>
