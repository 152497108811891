
import { mixins } from 'vue-class-component';
import RandomizeMixin from '@/mixins/RandomizeMixin';

export default class MainMenu extends mixins(RandomizeMixin) {
  random: {[key: string]: {value: unknown, options: unknown[]}} = {
    whatLabel: {
      value: 'What',
      options: [
        'What',
        'What',
        'What',
        'What',
        'What',
        'What',
        'What',
        'What',
        'WTF?',
      ],
    },
  }
}
