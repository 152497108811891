
import { Options, Vue } from 'vue-class-component';
import { Tag } from 'mdue';

type Project = {
  tags: string[],
};

@Options({
  components: {
    Tag,
  },
  props: {
    project: Object as () => Project,
  },
})
export default class ProjectTags extends Vue {
  project!: Project;

  tags: string[] = [];

  created(): void {
    this.tags = this.project.tags.sort();
  }
}
