<template>
  <div class="logo">
    <router-link to="/" class="nav-link">
      Ben McClure
    </router-link>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
  .logo
    margin-right spacing-normal-fixed

    .nav-link
      align-items center
      color color-lightest
      display flex
      font-size font-size-xl
      font-weight bold
      text-decoration none
</style>

<script lang="ts">
import { Vue } from 'vue-class-component';

export default class Logo extends Vue {

}
</script>
