<template>
  <transition appear name="modal" @enter="enter" @leave="leave"
    @after-leave="afterLeave">
    <div v-if="show && internalShow" class="modal-mask" @click.self="closeModal">
      <transition name="modal-container" @enter="containerEnter" @leave="containerLeave">
        <div class="modal-container" v-if="showContainer">
          <div class="modal-buttons">
            <slot name="buttons">
              <a href="#" class="modal-button modal-button--close" @click.prevent="closeModal">
                <Close></Close>
              </a>
            </slot>
          </div>
          <div class="modal-header">
            <slot name="header">
              <h2 class="modal-title">{{title}}</h2>
            </slot>
          </div>

          <div class="modal-body">
            <slot name="body">

            </slot>
          </div>

          <div class="modal-footer">
            <slot name="footer">

            </slot>
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>

<style lang="stylus">
  .modal-enter-from
    opacity 0

  .modal-container-enter-from
    opacity 0
</style>

<style scoped lang="stylus">
  .modal-mask
    align-items center
    background-color rgba(0, 0, 0, 0.65)
    bottom 0
    display flex
    justify-content center
    left 0
    position fixed
    right 0
    top 0
    z-index 9998

  .modal-container
    background color-dark-bg
    border border-normal
    border-radius 2px
    box-shadow shadow-normal
    box-sizing border-box
    height auto
    max-height 95%
    max-width 900px
    overflow-y auto
    padding  spacing-normal-fixed spacing-large-fixed
    position relative
    width 95%

  .modal-title
    align-items center
    color color-lighter
    display flex
    margin 0 0 spacing-normal-fixed

  .modal-body
    margin spacing-normal-fixed 0

  .modal-buttons
    border-radius border-radius-large
    border-top-left-radius 0
    border-top-right-radius 0
    box-shadow shadow-normal
    position absolute
    right spacing-normal-fixed * 1.5
    top 0

  .modal-button
    background-color color-dark-accent
    border border-normal
    border-top none
    color color-lighter
    display block
    font-size font-size-button
    padding (spacing-tiny-fixed / 3) spacing-tiny-fixed

    &:first-child
      border-bottom-left-radius border-radius-large

    &:last-child
      border-bottom-right-radius border-radius-large

    &:hover
      background-color color-medium
      border-color color-medium-accent
      color color-lighter

    svg
      display block
</style>

<script lang="ts">
import { mixins, Options } from 'vue-class-component';
import { Close } from 'mdue';
import AnimationMixin from '@/mixins/AnimationMixin';

@Options({
  components: {
    Close,
  },
  props: {
    title!: String,
    show: Boolean,
  },
})
export default class Modal extends mixins(AnimationMixin) {
  title = '';

  show = false;

  internalShow = true;

  showContainer = false;

  closeModal(): void {
    this.showContainer = false;
  }

  enter(el: HTMLElement, done: CallableFunction): void {
    this.fadeIn(el, 250, done);
    setTimeout(() => { this.showContainer = true; }, 50);
  }

  leave(el: HTMLElement, done: CallableFunction): void {
    this.fadeOut(el, 250, done);
  }

  afterLeave(): void {
    this.$emit('close');
  }

  containerEnter(el: HTMLElement, done: CallableFunction): void {
    this.slideUp(el, 100, 250, done);
  }

  containerLeave(el: HTMLElement, done: CallableFunction): void {
    this.slideDown(el, 100, 250, done);
    setTimeout(() => { this.internalShow = false; }, 50);
  }
}
</script>
