<template>
  <div class="navbar">
    <Logo></Logo>
    <MainMenu></MainMenu>
    <SixSidedDice class="nav-dice"></SixSidedDice>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
  .navbar
    align-items center
    display flex
    flex-direction column
    font-family font-family-alt
    justify-content center
    padding spacing-tiny-fixed spacing-small-fixed
    text-transform uppercase

    @media screen and (min-width: screen-small)
      flex-direction row

    .nav-dice
      position absolute
      right spacing-small-fixed
      top spacing-small-fixed

      @media screen and (min-width: screen-small)
        position static
        right auto
        top auto
</style>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import Logo from '@/components/Logo.vue';
import MainMenu from '@/components/MainMenu.vue';
import SixSidedDice from '@/components/SixSidedDice.vue';

@Options({
  components: {
    Logo,
    MainMenu,
    SixSidedDice,
  },
})
export default class NavBar extends Vue {

}
</script>
