<template>
  <a
    :class="{ 'project-toggle-filter': true, 'active': this.active }"
    href="#"
    @click.prevent="toggleActive"
    :title="title ? title : null"
  >
    <slot></slot>
  </a>
</template>

<style lang="stylus">
  .project-toggle-filter > svg
    margin-right spacing-small-fixed
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
  .project-toggle-filter
    align-items center
    background-color color-dark
    border-radius border-radius-normal
    box-shadow shadow-normal
    color color-lightest
    display inline-flex
    padding spacing-tiny spacing-small
    text-decoration none
    transition transition-color-bg

    &.active
      background-color color-accent

    &:hover
      background-color color-highlight
      color color-lightest
</style>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    name: String,
    title!: String,
    startActive!: Boolean,
  },
})
export default class ProjectToggleFilter extends Vue {
  name = '';

  title = '';

  startActive = false;

  active = false;

  created(): void {
    this.active = this.startActive;
  }

  toggleActive(): void {
    this.active = !this.active;
    this.$emit('update:active', this.name, this.active);
  }
}
</script>
