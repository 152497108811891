<template>
  <div class="social">
    <iframe src="https://ghbtns.com/github-btn.html?user=bmcclure&type=sponsor" frameborder="0" scrolling="0" width="140" height="20" title="GitHub"></iframe>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
.social
  align-items center
  display flex
  flex-wrap wrap
  justify-content flex-end
  position absolute
  width 100%

  img
    padding spacing-small-fixed
</style>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {},
})
export default class Social extends Vue {}
</script>
