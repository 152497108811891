
import { Options, Vue } from 'vue-class-component';
import { ArrowTopRightThick } from 'mdue';
import Modal from './Modal.vue';
import ProjectMeta from './ProjectMeta.vue';
import ProjectTags from './ProjectTags.vue';

type Project = {
  class: string,
  created: string,
  description: string,
  status: string,
  tags: string[],
  title: string,
  type: string,
  url: string,
};

@Options({
  components: {
    ArrowTopRightThick,
    Modal,
    ProjectMeta,
    ProjectTags,
  },
  props: {
    project!: Object as () => Project,
    show: Boolean,
  },
})
export default class ProjectTeaser extends Vue {
  project!: Project;

  show = true;
}
