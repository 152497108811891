
import { mixins, Options } from 'vue-class-component';
import { Close } from 'mdue';
import AnimationMixin from '@/mixins/AnimationMixin';

@Options({
  components: {
    Close,
  },
  props: {
    title!: String,
    show: Boolean,
  },
})
export default class Modal extends mixins(AnimationMixin) {
  title = '';

  show = false;

  internalShow = true;

  showContainer = false;

  closeModal(): void {
    this.showContainer = false;
  }

  enter(el: HTMLElement, done: CallableFunction): void {
    this.fadeIn(el, 250, done);
    setTimeout(() => { this.showContainer = true; }, 50);
  }

  leave(el: HTMLElement, done: CallableFunction): void {
    this.fadeOut(el, 250, done);
  }

  afterLeave(): void {
    this.$emit('close');
  }

  containerEnter(el: HTMLElement, done: CallableFunction): void {
    this.slideUp(el, 100, 250, done);
  }

  containerLeave(el: HTMLElement, done: CallableFunction): void {
    this.slideDown(el, 100, 250, done);
    setTimeout(() => { this.internalShow = false; }, 50);
  }
}
