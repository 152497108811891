import './firebaseConfig';
import { createApp } from 'vue';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import mitt, { Emitter } from 'mitt';
import anime from 'animejs';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';

type Events = {
  randomize?: number;
};

declare module '@vue/runtime-core' {
  export interface ComponentCustomProperties {
    $emitter: Emitter<Events>
    $anime: (params: anime.AnimeParams) => anime.AnimeInstance
  }
}

const app = createApp(App);

app.config.globalProperties.$emitter = mitt<Events>();
app.config.globalProperties.$anime = anime;

app.use(Toast)
  .use(router)
  .mount('#app');
