
import { Options, Vue } from 'vue-class-component';
import { Tag } from 'mdue';

@Options({
  props: {
    tag: String,
    count: Number,
    startActive: Boolean,
  },
  components: {
    Tag,
  },
})
export default class ProjectTagFilter extends Vue {
  tag!: string;

  count = 0;

  startActive = false;

  active = false;

  create(): void {
    this.active = this.startActive;
  }

  toggleTag(): void {
    this.active = !this.active;
    this.$emit('update:active', this.tag, this.active);
  }
}
