
import { Options, Vue } from 'vue-class-component';
import Logo from '@/components/Logo.vue';
import MainMenu from '@/components/MainMenu.vue';
import SixSidedDice from '@/components/SixSidedDice.vue';

@Options({
  components: {
    Logo,
    MainMenu,
    SixSidedDice,
  },
})
export default class NavBar extends Vue {

}
