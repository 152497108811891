<template>
  <div class="projects">
    <div class="project-filters">
      <div class="project-listing-categories">
        <div class="project-listing-toggle-icon">
          <Folder></Folder>
        </div>

        <div class="project-listing-toggle-list">
          <ProjectToggleFilter
            v-for="category in categories"
            v-bind:key="category.name"
            :name="category.name"
            :startActive="category.startActive"
            :title="this.random[category.name + 'Title'].value"
            @update:active="toggleCategory"
            class="project-toggle-filter"
          >
            <component :is="category.icon"></component>
            {{this.random[category.name].value}}
          </ProjectToggleFilter>
        </div>
      </div>

      <div class="project-listing-filters">
        <div class="project-listing-toggle-icon">
          <PlusCircle></PlusCircle>
        </div>

        <div class="project-listing-toggle-list">
          <ProjectToggleFilter
            v-for="filter in filters"
            v-bind:key="filter.name"
            :name="filter.name"
            :startActive="filter.startActive"
            :title="this.random[filter.name + 'Title'].value"
            @update:active="toggleFilter"
            class="project-toggle-filter"
          >
            <component v-if="filter.icon" :is="filter.icon"></component>
            {{this.random[filter.name].value}}
          </ProjectToggleFilter>
        </div>
      </div>

      <div class="project-listing-tags">
        <div class="project-listing-toggle-icon">
          <TagText></TagText>
        </div>

        <div class="project-listing-toggle-list">
          <ProjectTagFilter v-for="(count, tag) in availableTags"
            :key="tag" :tag="tag" :count="count" :selected="selectedTags[tag]"
            @update:active="toggleTag" class="project-toggle-filter"></ProjectTagFilter>
        </div>
      </div>
    </div>

    <div class="project-results">
      <ProjectTeaser class="project-result" v-for="(project) in filteredProjects"
        :key="project.title" :project="project"
        @click="selectedProject = project; showModal = true;"></ProjectTeaser>
    </div>

    <div class="project-no-results" v-if="!Object.keys(filteredProjects).length">
      <h2>{{random.noResultsTitle.value}}</h2>
      <p>{{random.noResults.value}}</p>
    </div>

    <ProjectModal :project="selectedProject || undefined" :show="showModal"
      @close="closeModal">
    </ProjectModal>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
  .project-listing-categories
  .project-listing-filters
  .project-listing-tags
    display flex
    margin-bottom spacing-small-fixed

    .project-listing-toggle-icon
      align-items center
      display flex

      svg
        font-size font-size-xl
        margin-right spacing-small-fixed
        margin-bottom spacing-tiny-fixed

    .project-listing-toggle-list
      flex-grow 1

    .project-toggle-filter
      margin 0 spacing-small spacing-small 0

  .project-listing-categories
    font-size font-size-large

  .project-listing-filters
    font-size font-size-normal

  .project-listing-tags
    font-size font-size-small

  .projects
    margin-top spacing-normal-fixed

  .project-results
    display flex
    flex-wrap wrap

  .project-result
    flex-basis 100%

    @media screen and (min-width: screen-small)
      flex-basis 50%

    @media screen and (min-width: screen-med)
      flex-basis 33%

    @media screen and (min-width: screen-large)
      flex-basis 25%
</style>

<script lang="ts">
import { mixins, Options } from 'vue-class-component';
import {
  CodeTags,
  FlaskOutline,
  Music,
  Gamepad,
  Web,
  Folder,
  PlusCircle,
  TagText,
} from 'mdue';
import ProjectToggleFilter from '@/components/ProjectToggleFilter.vue';
import ProjectTagFilter from '@/components/ProjectTagFilter.vue';
import ProjectModal from '@/components/ProjectModal.vue';
import ProjectTeaser from '@/components/ProjectTeaser.vue';
import firebase from '../firebaseConfig';
import RandomizeMixin from '@/mixins/RandomizeMixin';

type Project = {
  categories: string[],
  class: string,
  created: string,
  description: string,
  filters: string[],
  status: string,
  tags: string[],
  title: string,
  type: string,
  url: string,
};

@Options({
  components: {
    ProjectToggleFilter,
    ProjectTagFilter,
    ProjectModal,
    ProjectTeaser,
    CodeTags,
    FlaskOutline,
    Music,
    Gamepad,
    Web,
    Folder,
    PlusCircle,
    TagText,
  },
})
export default class ProjectListing extends mixins(RandomizeMixin) {
  showModal = false;

  random: {[key: string]: {value: unknown, options: unknown[]}} = {
    Music: {
      value: 'Music',
      options: [
        'Muzak',
        'Earache',
        'Noise',
        'Ear food',
        'Aural pleasure',
        'Earbait',
      ],
    },
    MusicTitle: {
      value: 'Things that might tickle your eardrums, or smash them to bits...',
      options: [
        'Things that might tickle your eardrums, or smash them to bits...',
        'Non-discerning minds might call this music...',
        'Music for darklings...',
        'Subject yourself to my music...',
      ],
    },
    Gaming: {
      value: 'Gaming',
      options: [
        '"Games"',
        'Brainrot',
        'Is this fun?',
        'Bloody pixels',
        'Game over, man',
        'GPU candy',
        'Playbait',
        'Sweetcakes',
      ],
    },
    GamingTitle: {
      value: 'Things for folks who\'d rather be fragging...',
      options: [
        'Things for folks who\'d rather be fragging...',
        'Here there be dragons...',
        'These things will make you better at that game you suck at...',
        'You can\'t even play most of this crap...',
      ],
    },
    Websites: {
      value: 'Websites',
      options: [
        'Clickbait',
        'Webshites',
        'Browser candy',
        'Favorites',
        'Bookmarks',
        'Clicksores',
        'Eyesores',
      ],
    },
    WebsitesTitle: {
      value: 'Shady sites I\'ve created to convince you of something or another...',
      options: [
        'Shady sites I\'ve created to convince you of something or another...',
        'Probably just forms asking for money...',
        'Websites just like this one but different...',
        'I made these websites with my brain...',
      ],
    },
    Unreleased: {
      value: 'Unreleased',
      options: [
        'Workshop',
        'Neverware',
        'You wish...',
        'Half-baked',
        'Future failures',
        'Pre-vaporware',
        'Can\'t touch this',
        'Ghostbait',
        'Eye on the prize',
        'Procrasturbation',
      ],
    },
    UnreleasedTitle: {
      value: 'Things I haven\'t finished yet. Maybe I never will...',
      options: [
        'Things I haven\'t finished yet. Maybe I never will...',
        'Don\'t get your hopes up...',
        'Stuff for future generations...',
        'Maybe later, I\'m procrastinating...',
      ],
    },
    Code: {
      value: 'Code',
      options: [
        'Code den',
        'Boring',
        'tl;dr',
        'RTFM',
        'Gibberish',
        'Stack Underwhelm',
        'Nerdbait',
        'Dev pit',
      ],
    },
    CodeTitle: {
      value: 'Unbearable things like code and development libraries...',
      options: [
        'Unbearable things like code and development libraries...',
        'For developers only! All others, stay away...',
      ],
    },
    noResultsTitle: {
      value: 'Nein results!',
      options: [
        'Nein results!',
        'There\'s nothing here.',
        'It\'s lonely in here.',
        'Bam, brick wall.',
        'Game over. Continue?',
      ],
    },
    noResults: {
      value: 'Select some categories, quick!',
      options: [
        'Select some categories, quick!',
        'You went and fucked up, now...',
        'Ok, you unselected everything. Is this what you wanted?',
        'Why are you doing this to me?',
        'Don\'t hide my greatness!',
        'I order you to choose better categories immediately!',
        'Make better life choices.',
        'Look what you\'ve done... to my sheets...',
        'This is bullshit...',
      ],
    },
  };

  categories: {name: string, icon: string, startActive: boolean}[] = [
    {
      name: 'Music',
      icon: 'Music',
      startActive: true,
    },
    {
      name: 'Gaming',
      icon: 'Gamepad',
      startActive: true,
    },
    {
      name: 'Websites',
      icon: 'Web',
      startActive: true,
    },
    {
      name: 'Code',
      icon: 'CodeTags',
      startActive: false,
    },
  ];

  filters: {name: string, icon: string, startActive: boolean}[] = [
    {
      name: 'Unreleased',
      icon: 'FlaskOutline',
      startActive: false,
    },
  ];

  db: firebase.firestore.Firestore|null = null;

  projects: Project[] = [];

  tags: { [key: string]: number } = {};

  selectedTags: { [key: string]: boolean } = {};

  selectedProject: Project|false = false;

  includeCategories: {[key: string]: boolean} = {
    Music: true,
    Gaming: true,
    Websites: true,
    Code: false,
  };

  includeFilters: {[key: string]: boolean} = {
    Unreleased: false,
  };

  get filteredProjects(): Project[] {
    const filtered: Project[] = [];
    const hasSelectedTags = this.hasSelectedTags();

    this.projects.forEach((project) => {
      let included = false;

      Object.keys(this.includeCategories).every((category) => {
        if (this.includeCategories[category] && project.categories.includes(category)) {
          included = true;
          return false;
        }

        return true;
      });

      if (included) {
        project.filters.every((filter) => {
          if (!Object.prototype.hasOwnProperty.call(this.includeFilters, filter)
            || !this.includeFilters[filter]) {
            included = false;
            return false;
          }

          return true;
        });
      }

      if (included && hasSelectedTags) {
        Object.keys(this.selectedTags).every((tag) => {
          if (this.selectedTags[tag] && !project.tags.includes(tag)) {
            included = false;
            return false;
          }

          return true;
        });
      }

      if (included) {
        filtered.push(project);
      }
    });

    return filtered;
  }

  get availableTags(): { [key: string]: number } {
    const tags: { [key: string]: number } = {};

    this.filteredProjects.forEach((project) => {
      Object.values(project.tags).forEach((tag: string) => {
        if (!Object.prototype.hasOwnProperty.call(tags, tag)) {
          tags[tag] = 0;
        }

        tags[tag] += 1;
      });
    });

    const sortedTags = Object.keys(tags)
      .sort((first, second) => {
        if (this.selectedTags[first] === this.selectedTags[second]) {
          if (tags[first] === tags[second]) {
            if (first === second) {
              return 0;
            }

            return first.toUpperCase() < second.toUpperCase() ? -1 : 1;
          }

          return tags[first] > tags[second] ? -1 : 1;
        }

        return this.selectedTags[first] ? -1 : 1;
      })
      .reduce((obj: { [key: string]: number }, key) => {
        // eslint-disable-next-line
        obj[key] = tags[key];
        return obj;
      }, {});

    return sortedTags;
  }

  closeModal(): void {
    this.showModal = false;

    setTimeout(() => {
      this.selectedProject = false;
    }, 500);
  }

  toggleTag(tag: string, active: boolean): void {
    this.selectedTags[tag] = active;
  }

  toggleCategory(category: string, active: boolean): void {
    this.includeCategories[category] = active;
  }

  toggleFilter(filter: string, active: boolean): void {
    this.includeFilters[filter] = active;
  }

  hasSelectedTags(): boolean {
    let hasSelectedTags = false;

    Object.keys(this.selectedTags).every((tag: string) => {
      if (this.selectedTags[tag]) {
        hasSelectedTags = true;
        return false;
      }

      return true;
    });

    return hasSelectedTags;
  }

  // eslint-disable-next-line
  created() {
    this.$emitter.on('randomize', () => this.randomizeProjects());
    this.db = firebase.firestore();

    const converter = () => ({
      toFirestore: (data: Partial<Project>) => data,
      fromFirestore: (snap: firebase.firestore.QueryDocumentSnapshot) => snap.data() as Project,
    });

    this.db.collection('projects').withConverter(converter()).get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        const docData = doc.data();
        this.projects.push(docData);

        Object.values(docData.tags).forEach((tag: string) => {
          if (!Object.prototype.hasOwnProperty.call(this.tags, tag)) {
            this.tags[tag] = 0;
          }

          this.tags[tag] += 1;
          this.selectedTags[tag] = false;
        });
      });
    });
  }

  randomizeProjects(): void {
    function shuffleProjects(a: Project[]): Project[] {
      /* eslint-disable no-param-reassign */
      for (let i = a.length - 1; i > 0; i -= 1) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
      }
      return a;
      /* eslint-enable */
    }

    shuffleProjects(this.projects);
  }
}
</script>
