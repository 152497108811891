<template>
  <div class="welcome">
    <h1>Welcome to my {{random.place.value}}.</h1>
    <p>I {{random.action.value.action}} and {{random.result.value}}
      {{random.action.value.reference}} below for your {{random.response.value}}.</p>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
  .welcome
    h1
      margin-bottom spacing-normal-fixed

    p:last-child
      margin-bottom spacing-normal-fixed * 1.5
</style>

<script lang="ts">
import { mixins, Options } from 'vue-class-component';
import RandomizeMixin from '@/mixins/RandomizeMixin';

@Options({
  props: {},
})
export default class Welcome extends mixins(RandomizeMixin) {
  random: {[key: string]: {value: unknown, options: unknown[]}} = {
    place: {
      value: 'world',
      options: [
        'world',
        'life',
        'inner sanctum',
        'domain',
        'realm',
        'grotesquerie',
        'golden palace',
        'haunted mansion',
        'beautiful dream',
        'nightmare',
        'dark carnival',
        'tortured soul',
      ],
    },
    action: {
      value: {
        action: 'ripped apart my insides',
        reference: 'them',
      },
      options: [
        {
          action: 'ripped apart my insides',
          reference: 'them',
        },
        {
          action: 'tore out my soul',
          reference: 'it',
        },
        {
          action: 'amassed untold horrors',
          reference: 'some',
        },
        {
          action: 'made all this garbage',
          reference: 'it',
        },
        {
          action: 'unleashed many atrocities',
          reference: 'them',
        },
        {
          action: 'created countless gems',
          reference: 'a few',
        },
        {
          action: 'made some things',
          reference: 'them',
        },
        {
          action: 'birthed a bunch of demons',
          reference: 'them',
        },
        {
          action: 'made some things you\'re gonna hate',
          reference: 'them',
        },
        {
          action: 'made these things just for you',
          reference: 'them',
        },
        {
          action: 'spewed out some code',
          reference: 'it',
        },
        {
          action: 'made some ghosts',
          reference: 'them',
        },
        {
          action: 'summoned this evil',
          reference: 'it',
        },
        {
          action: 'created some "art"',
          reference: 'it',
        },
      ],
    },
    result: {
      value: 'scattered',
      options: [
        'displayed',
        'scattered',
        'splayed',
        'positioned',
        'arranged',
        'dumped',
        'piled',
        'stacked',
        'curated',
      ],
    },
    response: {
      value: 'consumption',
      options: [
        'consumption',
        'dark uses',
        'unholy purposes',
        'morbid curiosity',
        'leisurely perusal',
        'sick desires',
        'feeding ritual',
      ],
    },
  }
}
</script>
