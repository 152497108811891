<template>
  <div class="home">
    <Welcome/>
    <ProjectListing/>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import Welcome from '@/components/Welcome.vue';
import ProjectListing from '@/components/ProjectListing.vue';

@Options({
  components: {
    Welcome,
    ProjectListing,
  },
})
export default class Home extends Vue {}
</script>
