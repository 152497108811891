
import { mixins, Options } from 'vue-class-component';
import { ArrowTopRightThick } from 'mdue';
import ProjectMeta from './ProjectMeta.vue';
import ProjectTags from './ProjectTags.vue';
import AnimationMixin from '@/mixins/AnimationMixin';

type Project = {
  class: string,
  created: string,
  description: string,
  status: string,
  tags: string[],
  title: string,
  type: string,
  url: string,
};

@Options({
  components: {
    ArrowTopRightThick,
    ProjectMeta,
    ProjectTags,
  },
  props: {
    project: Object as () => Project,
  },
})
export default class ProjectTeaser extends mixins(AnimationMixin) {
  project!: Project;

  hoverTeaser(hovering?: boolean): void {
    this.hoverSlide(this.$el, hovering);
  }
}
