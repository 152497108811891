<template>
  <div :class="'project project--' + project.class" @mouseover="this.hoverTeaser(true)"
    @mouseleave="this.hoverTeaser(false)">
    <div class="project-inner">
      <div v-if="project.status" class="project-status">{{ project.status }}</div>

      <h2>{{ project.title }} <ArrowTopRightThick></ArrowTopRightThick></h2>

      <ProjectMeta :project="project"></ProjectMeta>

      <p>{{ project.description }}</p>

      <ProjectTags :project="project"></ProjectTags>
    </div>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
  .project
    padding spacing-small-fixed
    box-sizing border-box
    box-shadow shadow-normal

    .project-inner
      background color-dark-bg
      border border-normal
      border-radius border-radius-normal
      box-sizing border-box
      display flex
      height 100%
      flex-direction column
      padding spacing-normal-fixed
      position relative
      transition transition-color-bg

      h2
        align-items center
        color color-accent
        display flex
        font-size font-size-large
        margin (spacing-small * 1.2) 0 spacing-small
        transition transition-color

        svg
          display none
          margin-left spacing-tiny

      &:hover
        background color-dark-bg-highlight
        cursor pointer

        h2 svg
          display inline

  .project:hover h2
    color color-highlight

  p
    flex-grow 1
    font-size font-size-small
    margin-top 0

  .project-status
    background-color color-darker
    color color-light
    font-size font-size-tiny
    padding spacing-tiny spacing-small
    position absolute
    right spacing-small-fixed
    top spacing-small-fixed
</style>

<script lang="ts">
import { mixins, Options } from 'vue-class-component';
import { ArrowTopRightThick } from 'mdue';
import ProjectMeta from './ProjectMeta.vue';
import ProjectTags from './ProjectTags.vue';
import AnimationMixin from '@/mixins/AnimationMixin';

type Project = {
  class: string,
  created: string,
  description: string,
  status: string,
  tags: string[],
  title: string,
  type: string,
  url: string,
};

@Options({
  components: {
    ArrowTopRightThick,
    ProjectMeta,
    ProjectTags,
  },
  props: {
    project: Object as () => Project,
  },
})
export default class ProjectTeaser extends mixins(AnimationMixin) {
  project!: Project;

  hoverTeaser(hovering?: boolean): void {
    this.hoverSlide(this.$el, hovering);
  }
}
</script>
