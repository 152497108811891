
import { Options, Vue } from 'vue-class-component';
import NavBar from '@/components/NavBar.vue';
import SocialLinks from '@/components/SocialLinks.vue';

@Options({
  components: {
    NavBar,
    SocialLinks,
  },
})

export default class App extends Vue {}
