import anime from 'animejs';
import { Vue } from 'vue-class-component';

export default class AnimationMixin extends Vue {
  fadeIn(el: HTMLElement|string, duration?: number, done?: CallableFunction): void {
    this.animate({
      opacity: 1,
      easing: 'easeInCubic',
    }, el, duration || 250, done);
  }

  fadeOut(el: HTMLElement|string, duration?: number, done?: CallableFunction): void {
    this.animate({
      opacity: 0,
      easing: 'easeOutCubic',
    }, el, duration || 250, done);
  }

  slideUp(el: HTMLElement|string, distance?: number, duration?: number,
    done?: CallableFunction): void {
    this.animate({
      translateY: distance || 100,
      complete: () => this.animate({
        translateY: 0,
        easing: 'easeInQuart',
      }, el, duration, done),
    }, el, 0);
  }

  slideDown(el: HTMLElement|string, distance?: number, duration?: number,
    done?: CallableFunction): void {
    this.animate({
      translateY: distance || 100,
      easing: 'easeOutQuart',
    }, el, duration, done);
  }

  shake(el: HTMLElement|string, distance?: number, duration?: number,
    done?: CallableFunction): void {
    const maxDistance = distance || 4;

    this.animate({
      easing: 'easeInExpo',
      translateX: [
        { value: maxDistance * -1 },
        { value: maxDistance },
        { value: maxDistance / -2 },
        { value: maxDistance / 2 },
        { value: 0 },
      ],
    }, el, duration || 500, done);
  }

  wiggle(el: HTMLElement|string, duration?: number, done?: CallableFunction): void {
    this.shake(el, 2, duration || 500, done);
  }

  hoverSlide(el: HTMLElement|string, hovering?: boolean, duration?: number,
    done?: CallableFunction): void {
    const distance = 2;
    const value = hovering ? distance * -1 : 0;

    this.animate({
      easing: 'easeInOutQuart',
      translateX: value,
    }, el, duration || 100, done);
  }

  animate(opts: anime.AnimeParams, el?: HTMLElement|string, duration?: number,
    done?: CallableFunction): void {
    const additional: anime.AnimeParams = {};

    if (el) {
      additional.targets = el;
    }

    if (typeof duration === 'number') {
      additional.duration = duration;
    }

    if (done) {
      additional.complete = () => done();
    }

    this.$anime({
      ...opts,
      ...additional,
    });
  }
}
