
import { Options, Vue } from 'vue-class-component';
import {
  Link,
  Calendar,
  Steam,
  GoogleController,
  Web,
  Music,
  Album,
  RobotIndustrial,
  AccountMusic,
} from 'mdue';

type Project = {
  class: string,
  created: string,
  description: string,
  status: string,
  tags: string[],
  title: string,
  type: string,
  url: string,
};

@Options({
  components: {
    Link,
    Calendar,
    Steam,
    GoogleController,
    Web,
    Music,
    Album,
    RobotIndustrial,
    AccountMusic,
  },
  props: {
    project: Object as () => Project,
  },
})
export default class ProjectMeta extends Vue {
  project!: Project;

  icons: { [key: string]: string } = {
    Default: 'RobotIndustrial',
    'Game software': 'Steam',
    'Music artist': 'AccountMusic',
    'Game soundtrack': 'Music',
    'Music release': 'Album',
    Website: 'Web',
    Game: 'GoogleController',
  };

  get icon(): string {
    let icon = this.icons.Default;

    if (Object.prototype.hasOwnProperty.call(this.icons, this.project.type)) {
      icon = this.icons[this.project.type];
    }

    return icon;
  }

  get baseUrl(): string {
    let hostname;

    if (this.project.url.indexOf('//') > -1) {
      [,, hostname] = this.project.url.split('/');
    } else {
      [hostname] = this.project.url.split('/');
    }

    [hostname] = hostname.split(':');
    [hostname] = hostname.split('?');

    return hostname;
  }
}
