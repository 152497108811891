import { Vue } from 'vue-class-component';

export default class RandomizeMixin extends Vue {
  random: {[key: string]: {value: unknown, options: unknown[]}} = {};

  created(): void {
    this.$emitter.on('randomize', () => this.randomize());
  }

  mounted(): void {
    this.randomize();
  }

  randomize(): void {
    Object.keys(this.random).forEach((key) => {
      const randomIndex = Math.floor(Math.random() * this.random[key].options.length);
      this.random[key].value = this.random[key].options[randomIndex];
    });
  }
}
