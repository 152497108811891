<template>
  <a :class="{ 'project-tag-filter': true, 'active': active }" href="#" @click.prevent="toggleTag">
    <Tag class="project-tag-filter-icon"></Tag>
    <span class="project-tag-filter-tag">
      {{ tag }}
    </span>
    <span class="project-tag-filter-count">({{ count }})</span>
  </a>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
  .project-tag-filter
    align-items center
    background-color color-dark
    border-radius border-radius-normal
    box-shadow shadow-normal
    color color-lightest
    display inline-flex
    font-size font-size-small
    padding spacing-tiny spacing-small
    text-decoration none
    transition transition-color-bg

    &.active
      background-color color-accent

      .project-tag-filter-icon
      .project-tag-filter-count
        color color-lightest

    &:hover
      background-color color-highlight
      color color-lightest

      .project-tag-filter-icon
      .project-tag-filter-count
        color color-lightest

    &:last-child
      margin-right 0

  .project-tag-filter-icon
    color color-light
    margin-right spacing-tiny

  .project-tag-filter-count
    color color-light
    font-size font-size-tiny
    margin-left spacing-tiny
</style>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Tag } from 'mdue';

@Options({
  props: {
    tag: String,
    count: Number,
    startActive: Boolean,
  },
  components: {
    Tag,
  },
})
export default class ProjectTagFilter extends Vue {
  tag!: string;

  count = 0;

  startActive = false;

  active = false;

  create(): void {
    this.active = this.startActive;
  }

  toggleTag(): void {
    this.active = !this.active;
    this.$emit('update:active', this.tag, this.active);
  }
}
</script>
