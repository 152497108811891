<template>
  <a class="dice-link" href="#" @click.prevent="this.clickDice()"
    @mouseover="this.hoverDice(true)" @mouseleave="this.hoverDice(false)" title="Roll the dice">
    <component :is="random.diceComponent.value" class="dice rollable-dice"></component>
  </a>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
  .dice-link
    color color-dim
    font-size font-size-small*2

    &:hover
      color color-lightest
</style>

<script lang="ts">
import { mixins, Options } from 'vue-class-component';
import {
  DiceMultiple,
  Dice_1 as Dice1,
  Dice_2 as Dice2,
  Dice_3 as Dice3,
  Dice_4 as Dice4,
  Dice_5 as Dice5,
  Dice_6 as Dice6,
} from 'mdue';
import anime from 'animejs';
import RandomizeMixin from '@/mixins/RandomizeMixin';
import AnimationMixin from '@/mixins/AnimationMixin';

type AnimeCallbackFunction = (anim: anime.AnimeInstance) => void;

@Options({
  components: {
    DiceMultiple,
    Dice1,
    Dice2,
    Dice3,
    Dice4,
    Dice5,
    Dice6,
  },
})
export default class SixSidedDice extends mixins(RandomizeMixin, AnimationMixin) {
  random: {[key: string]: {value: unknown, options: unknown[]}} = {
    diceComponent: {
      value: 'DiceMultiple',
      options: [
        'Dice1',
        'Dice2',
        'Dice3',
        'Dice4',
        'Dice5',
        'Dice6',
      ],
    },
  }

  created(): void {
    setInterval(() => this.wiggleDice(), 30000);
  }

  clickDice(): void {
    this.shakeDice(() => {
      this.$emitter.emit('randomize');
    });
  }

  hoverDice(hovering?: boolean): void {
    this.hoverSlide(this.$el, hovering);
  }

  wiggleDice(): void {
    this.wiggle(this.$el);
  }

  shakeDice(completeFunc?: AnimeCallbackFunction|undefined): void {
    this.shake(this.$el, 4, 500, completeFunc);
  }
}
</script>
