<template>
  <div class="project-meta-container">
    <span v-if="project.type" :class="'project-type project-meta project-type--' + project.class">
      <component v-bind:is="icon"/>
      {{ project.type }}
    </span>
    <span v-if="project.created" class="project-created project-meta">
      <Calendar></Calendar>
      {{ project.created }}
    </span>
    <span v-if="project.url" class="project-link project-meta">
      <a :href="project.url" target="_blank"><Link></Link>{{ baseUrl }}</a>
    </span>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
  .project-meta-container
    margin 0 0 spacing-normal-fixed

  .project-meta
    align-items center
    background-color color-darker
    box-shadow shadow-normal
    display inline-flex
    font-size font-size-tiny
    margin spacing-tiny-fixed spacing-tiny-fixed 0 0
    padding spacing-tiny spacing-small

    svg
      margin-right spacing-tiny

    a
      align-items center
      display inline-flex
      text-decoration none

    &.project-type
      &.project-type--game
        background darken(color-game, 50%)

      &.project-type--music
        background darken(color-music, 50%)

      &.project-type--website
        background darken(color-website, 50%)
</style>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import {
  Link,
  Calendar,
  Steam,
  GoogleController,
  Web,
  Music,
  Album,
  RobotIndustrial,
  AccountMusic,
} from 'mdue';

type Project = {
  class: string,
  created: string,
  description: string,
  status: string,
  tags: string[],
  title: string,
  type: string,
  url: string,
};

@Options({
  components: {
    Link,
    Calendar,
    Steam,
    GoogleController,
    Web,
    Music,
    Album,
    RobotIndustrial,
    AccountMusic,
  },
  props: {
    project: Object as () => Project,
  },
})
export default class ProjectMeta extends Vue {
  project!: Project;

  icons: { [key: string]: string } = {
    Default: 'RobotIndustrial',
    'Game software': 'Steam',
    'Music artist': 'AccountMusic',
    'Game soundtrack': 'Music',
    'Music release': 'Album',
    Website: 'Web',
    Game: 'GoogleController',
  };

  get icon(): string {
    let icon = this.icons.Default;

    if (Object.prototype.hasOwnProperty.call(this.icons, this.project.type)) {
      icon = this.icons[this.project.type];
    }

    return icon;
  }

  get baseUrl(): string {
    let hostname;

    if (this.project.url.indexOf('//') > -1) {
      [,, hostname] = this.project.url.split('/');
    } else {
      [hostname] = this.project.url.split('/');
    }

    [hostname] = hostname.split(':');
    [hostname] = hostname.split('?');

    return hostname;
  }
}
</script>
