<template>
  <div class="main-menu links">
    <router-link to="/" class="nav-link">{{ random.whatLabel.value }}</router-link>
    <span class="separator">|</span>
    <router-link to="/who" class="nav-link">Who</router-link>
    <span class="separator">|</span>
    <router-link to="/connect" class="nav-link">Connect</router-link>
  </div>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
  .main-menu
    display flex
    flex-grow 1
    font-size font-size-normal
    margin 0 spacing-normal-fixed

    .separator
      padding spacing-tiny-fixed spacing-small-fixed

    .nav-link
      color color-accent
      font-weight normal
      padding spacing-tiny-fixed
      text-decoration none
      transition transition-color

      &:hover,
      &.router-link-active
        color color-highlight
</style>

<script lang="ts">
import { mixins } from 'vue-class-component';
import RandomizeMixin from '@/mixins/RandomizeMixin';

export default class MainMenu extends mixins(RandomizeMixin) {
  random: {[key: string]: {value: unknown, options: unknown[]}} = {
    whatLabel: {
      value: 'What',
      options: [
        'What',
        'What',
        'What',
        'What',
        'What',
        'What',
        'What',
        'What',
        'WTF?',
      ],
    },
  }
}
</script>
