
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    name: String,
    title!: String,
    startActive!: Boolean,
  },
})
export default class ProjectToggleFilter extends Vue {
  name = '';

  title = '';

  startActive = false;

  active = false;

  created(): void {
    this.active = this.startActive;
  }

  toggleActive(): void {
    this.active = !this.active;
    this.$emit('update:active', this.name, this.active);
  }
}
