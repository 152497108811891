<template>
  <ul class="project-tags">
    <li class="project-tag" v-for="(tag) in tags" :key="tag"><Tag></Tag> {{ tag }}</li>
  </ul>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
  .project-tags
    list-style-type none
    margin 0
    padding-left 0

  .project-tag
    align-items: center
    color color-light
    display inline-flex
    font-size font-size-tiny
    margin spacing-tiny-fixed spacing-tiny-fixed 0 0
    padding spacing-tiny spacing-small
    text-decoration none

    svg
      color color-dim
      margin-right spacing-tiny

    &:last-child
      margin-right 0
</style>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Tag } from 'mdue';

type Project = {
  tags: string[],
};

@Options({
  components: {
    Tag,
  },
  props: {
    project: Object as () => Project,
  },
})
export default class ProjectTags extends Vue {
  project!: Project;

  tags: string[] = [];

  created(): void {
    this.tags = this.project.tags.sort();
  }
}
</script>
