<template>
  <Modal v-if="project" :class="'project-modal project-modal--' + project.class"
    :show="show">
    <template v-slot:header>
      <div v-if="project.status" class="project-status">{{ project.status }}</div>
      <h2 v-if="project.title" class="modal-title">{{project.title}}</h2>
      <ProjectMeta v-if="project" :project="project"></ProjectMeta>
    </template>

    <template v-slot:body>
      <p v-if="project">{{ project.description }}</p>

      <a :href="project.url" v-if="project.url" class="project-more-info">
        More information
        <ArrowTopRightThick></ArrowTopRightThick>
      </a>
    </template>

    <template v-slot:footer>
      <ProjectTags :project="project"></ProjectTags>
    </template>
  </Modal>
</template>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus">
  p
    flex-grow 1
    font-size font-size-normal
    margin-top 0

  .project-status
    background-color color-darker
    border-bottom-left-radius border-radius-large
    border-bottom-right-radius border-radius-large
    box-shadow shadow-normal
    color color-light
    font-size font-size-tiny
    left spacing-normal-fixed * 1.5
    padding spacing-tiny spacing-normal
    position absolute
    top 0

  .modal-title
    align-items center
    color color-lighter
    display flex
    margin spacing-normal-fixed 0 spacing-small-fixed

  .project-more-info
    align-items center
    background color-darker
    border-radius border-radius-normal
    display inline-flex
    box-shadow shadow-normal
    font-family font-family-alt
    padding spacing-tiny spacing-normal
    text-decoration none

    svg
      margin-left spacing-small
</style>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { ArrowTopRightThick } from 'mdue';
import Modal from './Modal.vue';
import ProjectMeta from './ProjectMeta.vue';
import ProjectTags from './ProjectTags.vue';

type Project = {
  class: string,
  created: string,
  description: string,
  status: string,
  tags: string[],
  title: string,
  type: string,
  url: string,
};

@Options({
  components: {
    ArrowTopRightThick,
    Modal,
    ProjectMeta,
    ProjectTags,
  },
  props: {
    project!: Object as () => Project,
    show: Boolean,
  },
})
export default class ProjectTeaser extends Vue {
  project!: Project;

  show = true;
}
</script>
